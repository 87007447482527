import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { ReactNode } from "react";
import { ThemeProvider } from "../theme";
import { ApiMessagesProvider } from "./apiMessagesProvider";
import { AuthProvider } from "./authProvider";
import { BankAccountRejectForm } from "./bankAccountRejectFromProvider/providers";
import { CoinsProvider } from "./coinsProvider";

const Client = new QueryClient();

function Providers({ children }: { children: ReactNode }) {
  return (
    <QueryClientProvider client={Client}>
      <ThemeProvider>
        <ApiMessagesProvider>
          <CoinsProvider>
            <AuthProvider>
              <BankAccountRejectForm.Provider>
                {children}
              </BankAccountRejectForm.Provider>
            </AuthProvider>
          </CoinsProvider>
        </ApiMessagesProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export { Providers };
