import { createFormContext } from "react-hook-form-context";

interface BankAccountRejectFormProps {
  uuid: string;
  reasons: string[];
}
const initialValue: BankAccountRejectFormProps = {
  uuid: "",
  reasons: [],
};
export const BankAccountRejectForm = createFormContext(initialValue);
