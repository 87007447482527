import { createContext, ReactNode, useContext } from "react";
import { CoinInformation } from "../../constants/types";
import { RequestError } from "../../services/config";
import { useGetExchange_dataCoins } from "../../services/hooks";

interface CoinsContextProps {
  coinsData: CoinInformation[];
  isLoading?: boolean;
  error?: RequestError | Error | null;
  refetchCoinsData: () => void;
}

const initialValue: CoinsContextProps = {
  coinsData: [],
  isLoading: false,
  error: null,
  refetchCoinsData: () => undefined,
};

export const CoinsContext = createContext(initialValue);

export const CoinsProvider = ({ children }: { children: ReactNode }) => {
  const {
    data,
    isLoading,
    error,
    refetch: refetchCoinsData,
  } = useGetExchange_dataCoins({
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const coinsData = (data?.result || []) as CoinInformation[];

  return (
    <CoinsContext.Provider
      value={{ coinsData, isLoading, error, refetchCoinsData }}
    >
      {children}
    </CoinsContext.Provider>
  );
};

export const useCoinsData = () => {
  const { coinsData, error, isLoading, refetchCoinsData } =
    useContext(CoinsContext);
  return { coinsData, error, isLoading, refetchCoinsData };
};
