export const colors = {
  lightTheme: {
    main: "#ffffff",
    onMain: "#525b75",
    onMainHover: "#3e465b",

    secondary: "#f5f7fa",
    onSecondary: "#141824",
    secondaryHover: "#eff2f6",

    dropdown: "#ffffff",
    onDropdown: "#31374a",

    pending: "#ffefca",
    onPending: "#bc3803",
    pendingBorder: "#ffcc85",

    approve: "#d9fbd0",
    approveHover: "#25b003",
    onApprove: "#1c6c09",
    onApproveHover: "#fff",
    approveBorder: "#bee8b4",
    approveBorderHover: "#23890b",

    error: "#dc354515",
    errorHover: "#ed2000",
    onError: "#dc3545",
    onErrorHover: "#fff",
    errorBorder: "#ff9fad",
    errorBorderHover: "#cc1b00",

    primary: "#3874ff",
    onPrimary: "#ffffff",
    primaryHover: "#004dff",
    primaryDark: "#3a57bf",

    rating: "#e5780b",
    ratingOutline: "#ffd499",

    process: "#e5edff",
    onProcess: "#003cc7",
    processBorder: "#adc5ff",
    new: "#c7ebff",
    onNew: "#005585",
    newBorder: "#96d9ff",
    neutral: "#e3e6ed",
    onNeutral: "#141824",
    neutralBorder: "#cbd0dd",

    tableInfo: "#222834",
    hover: "#e3e6ed",

    theme: "#fff3e2",
    onTheme: "#e5780b",
    themeHover: "#e5780b",
    onThemeHover: "#fff6e0",

    tableItem: "#31374a",
    review: "#222834",
    searchHover: "#e3e6ed",
    border: "#cbd0dd",

    warning: "#ff6700",
    accent: "#525b75",
    title: "#141824",
    logo: "#6e7891",

    constantWhite: "#ffffff",
    constantDark: "#0f111a",

    disable: "#e3e6ed",
    onDisable: "#141824",
    disableBorder: "#cbd0dd",

    chartOne: "#0080c7",
    // chartTwo: "#3874ff",
    chartTwo: "#adc5ff",
    // chartThree: "#adc5ff",
    chartThree: "#dc3545",
    // chartFour: "#e5edff",
    chartFour: "#e3e6ed",

    cardBg: "#e6e6e6",
    cardBgSecondary: "#d9d9d9",
    cancel: "#DA70D6",
    cancelBg: "#DA70D620",

    problematicBg: "#C7253E20",
    onProblematic: "#C7253E",
    waitBg: "#A192B520",
    onWait: "#A192B5",
    validationBg: "#E85C0D20",
    onvalidation: "#E85C0D",
  },
  darkTheme: {
    main: "#141824",
    onMain: "#9fa6bc",
    onMainHover: "#eff2f6",

    secondary: "#0f111a",
    onSecondary: "#e3e6ed",
    secondaryHover: "#222834",

    dropdown: "#141824",
    onDropdown: "#9fa6bc",

    pending: "#ffefca08",
    onPending: "#ffcc85",
    pendingBorder: "#ffcc8520",

    approve: "#d9fbd008",
    approveHover: "#25b003",
    onApprove: "#90d67f",
    onApproveHover: "#fff",
    approveBorder: "#90d67f20",
    approveBorderHover: "#23890b",

    error: "#dc354520",
    errorHover: "#ed2000",
    onError: "#dc3545",
    onErrorHover: "#fff",
    errorBorder: "#dc354580",
    errorBorderHover: "#cc1b00",

    primary: "#3874ff",
    onPrimary: "#ffffff",
    primaryHover: "#004dff",
    primaryDark: "#3a57bf",

    rating: "#ffcc85",
    ratingOutline: "#e7ba7b",

    process: "#85a9ff08",
    onProcess: "#85a9ff",
    processBorder: "#85a9ff20",
    new: "#60c6ff08",
    onNew: "#60c6ff",
    newBorder: "#60c6ff20",
    neutral: "#31374a08",
    onNeutral: "#cbd0dd",
    neutralBorder: "#cbd0dd20",

    tableInfo: "#cbd0dd",
    hover: "#31374a",

    theme: "#1c2e58",
    onTheme: "#85a9ff",
    themeHover: "#3874ffb3",
    onThemeHover: "#f5f8ff",

    tableItem: "#9fa6bc",
    review: "#cbd0dd",
    searchHover: "#cbd0dd",
    border: "#31374a",
    warning: "#ff6700",
    accent: "#8a94ad",
    title: "#141824",
    logo: "#eff2f6",

    constantWhite: "#ffffff",
    constantDark: "#0f111a",

    disable: "#31374a14",
    onDisable: "#cbd0dd",
    disableBorder: "#cbd0dd33",

    chartOne: "#0099eb",
    // chartTwo: "#85aaff",
    chartTwo: "#adc5ff",
    // chartThree: "#adc5ff",
    chartThree: "#dc3545",
    // chartFour: "#1d2332",
    chartFour: "#cbd0dd",

    cardBg: "#394052",
    cardBgSecondary: "#21242f",

    cancel: "#DA70D6",
    cancelBg: "#DA70D620",

    problematicBg: "#C7253E20",
    onProblematic: "#C7253E",
    waitBg: "#D7C3F120",
    onWait: "#D7C3F1",
    validationBg: "#E85C0D20",
    onvalidation: "#E85C0D",
  },
};

export type Colors = typeof colors.lightTheme;
