import { createUseStyles } from "react-jss";
import { ScaleLoader } from "react-spinners";
import { useColors } from "../../../core/hooks/useColors";
import { Colors } from "../../../core/theme/colors";

const LoadingScreen = () => {
  const { colors } = useColors();
  const classes = useClasses();

  return (
    <div className={classes.loadingScreen}>
      <ScaleLoader color={colors.onMain} height={28} width={10} />
    </div>
  );
};

const useClasses = createUseStyles((colors: Colors) => ({
  loadingScreen: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.main,
  },
}));

export { LoadingScreen };
