import "jalaali-react-date-picker/lib/styles/index.css";
import React, { ReactNode, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import App from "./app/App";
import { LoadingScreen } from "./components/atoms/loadingScreen";
import "./core/i18n";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const FontsLoading = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!document.fonts) return;

    document.fonts.ready.then(() => {
      setIsLoading(false);
    });
  }, []);

  return isLoading ? <LoadingScreen /> : <>{children}</>;
};

root.render(
  <React.StrictMode>
    <FontsLoading>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </FontsLoading>
  </React.StrictMode>,
);
