import { useQuery } from "@tanstack/react-query";
import { ConfigProvider } from "antd";
import localeEn from "antd/lib/locale/en_US";
import localeFa from "antd/lib/locale/fa_IR";
import moment from "moment-jalaali";
import { ReactNode, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider as JssThemeProvider } from "react-jss";
import { ToastContainer } from "react-toastify";
import { LoadingScreen } from "../components/atoms/loadingScreen";
import { persistKeys } from "../core/constants/persistKeys";
import { i18nInitial } from "../core/i18n";
import { Theme, ThemeContext } from "../core/theme";
import { colors } from "../core/theme/colors";
import { ApplicationRoutes } from "./ApplicationRoutes";

const AppContainer = () => {
  const { setTheme } = useContext(ThemeContext);

  const { data: canLoadAPP } = useQuery(
    ["initialLoad"],
    async () => {
      const data = {
        Theme: localStorage.getItem(persistKeys.THEME),
        Language: localStorage.getItem(persistKeys.LANGUAGE),
      };

      setTheme((data.Theme || "dark") as Theme);
      await i18nInitial(data.Language || ("fa" as any));

      if (data.Language !== "en") {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const faLocale = require("moment/locale/fa");
        moment.updateLocale("fa", faLocale);
        moment.loadPersian({
          dialect: "persian-modern",
        });
      }

      return data;
    },
    {
      retry: false,
      cacheTime: 0,
      staleTime: Infinity,
    },
  );

  return canLoadAPP ? (
    <Child>
      <ApplicationRoutes />
      <ToastContainer />
    </Child>
  ) : (
    <LoadingScreen />
  );
};

const Child = ({ children }: { children: ReactNode }) => {
  const { i18n } = useTranslation();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    document.body.style.direction = i18n.language === "fa" ? "rtl" : "ltr";
    document.body.style.backgroundColor = "transparent";

    document.body.style.fontFamily =
      i18n.language === "fa" ? "Ariomex" : "Vazirmatn";
  }, [i18n.language]);

  return (
    <ConfigProvider
      direction={i18n.language === "fa" ? "rtl" : "ltr"}
      locale={i18n.language === "fa" ? localeFa : localeEn}
    >
      <JssThemeProvider
        theme={theme === "light" ? colors.lightTheme : colors.darkTheme}
      >
        {children}
      </JssThemeProvider>
    </ConfigProvider>
  );
};

export { AppContainer };
