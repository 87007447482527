import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en-US";
import fa from "./locales/fa-IR";

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const languageNames = {
  fa: "فارسی",
  en: "English",
};

type Language = keyof typeof languageNames;

i18n.use(initReactI18next);

function i18nInitial(lng: Language) {
  return i18n.init({
    resources: {
      fa: {
        translation: fa,
      },
      en: {
        translation: en,
      },
    },
    lng,
    fallbackLng: "fa",
    debug: false,
    returnNull: false,

    interpolation: {
      escapeValue: false,
    },
  });
}

export { i18nInitial };
export default i18n;
