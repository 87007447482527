import { createContext, ReactNode, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { RequestError } from "../../services/config";
import { useGetCodesError, useGetCodesSuccess } from "../../services/hooks";
import type { ApiErrorMessages, ApiSuccessMessages } from "./messageTypes";

interface ApiMessagesContextProps {
  error: {
    en: Record<string, string>;
    fa: Record<string, string>;
  };
  success: {
    en: Record<string, string>;
    fa: Record<string, string>;
  };
}

const initialValues: ApiMessagesContextProps = {
  error: {
    en: {},
    fa: {},
  },
  success: {
    en: {},
    fa: {},
  },
};

export const ApiMessageContext = createContext(initialValues);

export const ApiMessagesProvider = ({ children }: { children: ReactNode }) => {
  const [message, setMessage] = useState<ApiMessagesContextProps>({
    error: {
      en: {},
      fa: {},
    },
    success: {
      en: {},
      fa: {},
    },
  });

  useGetCodesError({
    onSuccess: (data) => {
      setMessage({ error: data as any, success: message.success });
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  useGetCodesSuccess({
    onSuccess: (data) => {
      setMessage({ success: data as any, error: message.error });
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return (
    <ApiMessageContext.Provider
      value={{ error: message.error, success: message.success }}
    >
      {children}
    </ApiMessageContext.Provider>
  );
};

export const useGetAPIMessages = () => {
  const { t, i18n } = useTranslation();

  const { error, success } = useContext(ApiMessageContext);

  const errors = i18n.language === "en" ? error.en : error.fa;
  const getErrorMessages = (error: RequestError | Error | null | undefined) => {
    if (!error) {
      return "";
    }

    if (error && error?.message === "noInternetConnection") {
      return t("noInternetConnection");
    }

    // @ts-ignore
    return errors?.[error?.message?.error] || t("somethingWentWrong");
  };

  const successes = i18n.language === "en" ? success.en : success.fa;
  const getSuccessMessages = (message: ApiSuccessMessages) => {
    return successes?.[message];
  };

  return { getErrorMessages, getSuccessMessages };
};

export { ApiErrorMessages, ApiSuccessMessages };
