import { createUseStyles } from "react-jss";
import { useColors } from "../core/hooks/useColors";
import { Providers } from "../core/providers";
import { AppContainer } from "./appContainer";

function App() {
  const { colors } = useColors();
  const classes = useClasses();

  return (
    <Providers>
      <div className={classes.app} style={{ backgroundColor: colors.main }}>
        <AppContainer />
      </div>
    </Providers>
  );
}

const useClasses = createUseStyles({
  app: {
    height: "100vh",
    display: "flex",
    flex: 1,
  },
});

export default App;
