import { useContext } from "react";
import { ThemeContext } from "../../theme";
import { colors as defaultColors } from "../../theme/colors";

export const useColors = () => {
  const { theme } = useContext(ThemeContext);

  const colors =
    theme === "light" ? defaultColors.lightTheme : defaultColors.darkTheme;

  return { colors, theme };
};
