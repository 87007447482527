import { lazy, Suspense } from "react";
import { LoadingScreen } from "../../components/atoms/loadingScreen";

type Page = Parameters<typeof lazy>[0];

export function createLazy(page: Page) {
  const Result = lazy(page);

  const Component = () => {
    return (
      <Suspense fallback={<LoadingScreen />}>
        <Result />
      </Suspense>
    );
  };

  return Component;
}
